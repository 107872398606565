import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { routes } from "../../routes";
import usePromise from "../../hooks/usePromise/usePromise";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import CareerGameplan from "./CareerGameplan/CareerGameplan";
import CareerGameplanTest from "./CareerGameplan/CareerGameplanTest/CareerGameplanTest";
import Checklist from "./CheckList/Checklist";
import Calendar from "./Calendar/Calendar";
import BookAppointment from "./BookAppointment/BookAppointment";
import OPTCalculator from "./OPTCalculator/OPTCalculator";
import Container from "../../components/Container/Container";
import BookMeeting from "./BookAppointment/BookMeeting/BookMeeting";
import BookAppointmentWithAdmin from "../../components/Tools/BookAppointment/BookAppointmentWithAdmin/BookAppointmentWithAdmin";
import { getMeetingPurposes } from "../../services/meetingPurposeServices";
import { STUDENT_VIEW_PREFERENCES_OPTIONS } from "../../utils/common";

import toolsCareerIcon from "../../assets/svg/toolsCareerIcon.svg";
import toolsChecklistIcon from "../../assets/svg/toolsChecklistIcon.svg";
import CareerGameplanFocusIcon from "../../assets/FocusIcons/MenuIcons/Tools/CareerGameplan.svg";
import ChecklistFocusIcon from "../../assets/FocusIcons/MenuIcons/Tools/Checklist.svg";
import optCalculatorIcon from "../../assets/svg/opt-calculator-header.svg";
import toolsAppointmentIcon from "../../assets/png/f-meet.png";

const Tools = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { permissions = {}, users = {} } = useSelector(
    (store) => store.userStore
  );
  const {
    student_view_preference = STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL,
  } = users || {};
  const [showBookAppointment, setShowBookAppointment] = useState(true);
  const [callGetMeetingPurposes, refreshGetMeetingPurposes] =
    usePromise(getMeetingPurposes);

  useEffect(() => {
    if (isEmpty(permissions)) return;

    if (
      (location.pathname.includes(routes.TOOLS.CAREER) &&
        !permissions["gameplan"]) ||
      (location.pathname.includes(routes.TOOLS.CHECKLIST) &&
        !permissions["checklist"]) ||
      (location.pathname.includes(routes.TOOLS.OPT_CALCULATOR) &&
        !(
          permissions["opt_calculator"] &&
          student_view_preference ===
            STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL
        )) ||
      ((location.pathname.includes(routes.TOOLS.MEET) ||
        location.pathname.includes(routes.TOOLS.BOOK_MEETING) ||
        location.pathname.includes("/calendar")) &&
        !(
          permissions["appointment"] &&
          student_view_preference ===
            STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL
        ))
    ) {
      navigate(routes.DASHBOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  useEffect(() => {
    if (!showBookAppointment) navigate(routes.DASHBOARD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBookAppointment]);

  useEffect(() => {
    if (
      callGetMeetingPurposes.hasFetched() &&
      !callGetMeetingPurposes.hasErrors()
    ) {
      let appointmentSettings =
        callGetMeetingPurposes.data()?.data?.appointment_settings;
      if (appointmentSettings && appointmentSettings.length > 0) {
        setShowBookAppointment(true);
      } else {
        setShowBookAppointment(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetMeetingPurposes.isFetching()]);

  useEffect(() => {
    async function getMeetingPurposes() {
      let response = await refreshGetMeetingPurposes();
      let appointmentSettings = response?.data?.appointment_settings;
      if (appointmentSettings && appointmentSettings.length > 0) {
        setShowBookAppointment(true);
      } else {
        setShowBookAppointment(false);
      }
    }
    getMeetingPurposes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tabs = [
    ...(permissions["gameplan"]
      ? [
          {
            title: "Career Gameplan",
            toolTip: "Test your career readiness",
            avatar: toolsCareerIcon,
            focusAvatar: CareerGameplanFocusIcon,
            navigateURL: routes.TOOLS.CAREER,
            isActive: location.pathname === routes.TOOLS.CAREER,
          },
          {
            title: "Career Gameplan Questionnaire",
            toolTip: "",
            avatar: toolsCareerIcon,
            focusAvatar: CareerGameplanFocusIcon,
            navigateURL: routes.TOOLS.CAREER_TEST,
            isActive: location.pathname === routes.TOOLS.CAREER_TEST,
            isDisplay: false,
          },
        ]
      : []),
    ...(permissions["opt_calculator"] &&
    student_view_preference === STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL
      ? [
          {
            title: "OPT Calculator",
            avatar: optCalculatorIcon,
            focusAvatar: optCalculatorIcon,
            navigateURL: routes.TOOLS.OPT_CALCULATOR,
            fullURL: location.href,
            isActive: location.pathname === routes.TOOLS.OPT_CALCULATOR,
          },
        ]
      : []),
    ...(permissions["checklist"]
      ? [
          {
            title: "Checklist",
            toolTip:
              "To prepare for your job search, interviews, and negotiations",
            avatar: toolsChecklistIcon,
            focusAvatar: ChecklistFocusIcon,
            navigateURL: routes.TOOLS.CHECKLIST,
            isActive: location.pathname === routes.TOOLS.CHECKLIST,
          },
        ]
      : []),
    ...(permissions["appointment"] &&
    showBookAppointment &&
    student_view_preference === STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL
      ? [
          {
            title: "Meet",
            avatar: toolsAppointmentIcon,
            focusAvatar: toolsAppointmentIcon,
            navigateURL: routes.TOOLS.MEET,
            fullURL: location.href,
            isActive: location.pathname === routes.TOOLS.MEET,
          },
        ]
      : []),
  ];
  return (
    <MainLayout
      title="Tools Dashboard"
      withSubHeader={true}
      tabs={tabs}
      breadCrumb={["Tools", "Career Gameplan"]}
      location={location}
      viewOnlyNavbar
      containerClassName={
        location.pathname === routes.TOOLS.CAREER_TEST
          ? "career-game-plan__wrapper"
          : ""
      }
    >
      <Container>
        <Routes>
          <Route path="/" element={<CareerGameplan />} />

          {permissions["gameplan"] && (
            <>
              <Route path="/career-gameplan" element={<CareerGameplan />} />
              <Route
                path="/career-gameplan/career-gameplan-test"
                element={<CareerGameplanTest />}
              />
            </>
          )}

          {permissions["checklist"] && (
            <Route path="/checklist" element={<Checklist />} />
          )}

          {permissions["appointment"] &&
            student_view_preference ===
              STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL && (
              <>
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/meet" element={<BookAppointment />} />
                <Route path="/book-meeting" element={<BookMeeting />} />
                <Route
                  path="/book-meeting/:id"
                  element={<BookAppointmentWithAdmin />}
                />
              </>
            )}
          {permissions["opt_calculator"] &&
            student_view_preference ===
              STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL && (
              <Route path="/opt-calculator" element={<OPTCalculator />} />
            )}
        </Routes>
      </Container>
    </MainLayout>
  );
};

export default Tools;
