import React, { useEffect } from "react";

import { getCookie, setCookie } from "../../helper/helper";

const GoogleTranslate = () => {
  // Make the function globally available
  window.googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: true,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    // Dynamically load the Google Translate script
    const scriptId = "google-translate-script";
    if (!document.getElementById(scriptId)) {
      const addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      addScript.id = scriptId;
      document.body.appendChild(addScript);
    }
  }, []);

  useEffect(() => {
    const getPreferredLanguage = () => {
      const languages = navigator.languages || [
        navigator.language || navigator.userLanguage,
      ];
      return languages[0].split("-")[0];
    };

    const manageCookie = (lang) => {
      const storedCookie = getCookie("googtrans");
      if (!storedCookie || !storedCookie.includes(`/${lang}`)) {
        setCookie("googtrans", `/en/${lang}`);
      }
    };

    const autoTranslate = () => {
      const lang = getPreferredLanguage();
      manageCookie(lang);

      setTimeout(() => {
        const select = document.querySelector("select.goog-te-combo");
        if (select) {
          select.value = lang;
          select.dispatchEvent(new Event("change"));
        }

        // Reset body to top
        document.body.style.top = 0;
      }, 1000);

      setTimeout(() => {
        // Reset body to top
        document.body.style.top = 0;
      }, 2000);
    };

    // Initialize translation on mount
    autoTranslate();

    // Listen for language changes
    const handleLanguageChange = () => {
      autoTranslate();
      setTimeout(() => {
        window.location.reload(true);
      }, 500);
    };

    window.addEventListener("languagechange", handleLanguageChange);

    return () => {
      window.removeEventListener("languagechange", handleLanguageChange);
    };
  }, []);

  return <div id="google_translate_element" style={{ display: "none" }} />;
};

export default GoogleTranslate;
